import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../components/Sidebar/Sidebar'

const WaterTreatment = () => (
    <Layout>
      <Helmet>
          <title>IN-ECO Water Treatment Chemicals</title>
      </Helmet>
      <Header
        styleName='header header-water'
        title='Water treatment products'
        />
      <div className="columns">
        <div className="column content">
          <div className="content-text">The company JSC "Inžinerinė ekologija" is a specialist in the field of chemical water treatment and is ready to inspect your systems, make analysis of water and prepare an individual water treatment program.</div>
          <div className="title-small content-title">Chemical treatment of steam boilers and heating systems</div>
          <div className="content-text">IN-ECO chemicals minimize corrosion processes, eliminate formation of scale, regulate pH and create conditions for excellent passivation. These factors lead to the optimised heat transfer, improve the quality of steam, reduce fuel consumption and improve efficiency.</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Corrosion and scale inhibitors</td>
              <td>IN-ECO 301; 307; 308; 315</td>
            </tr>
            <tr>
              <td>Oxygen scavengers</td>
              <td>IN-ECO 324-2; 326</td>
            </tr>
            <tr>
              <td>Corrosion inhibitors for condensate lines</td>
              <td>IN-ECO 332; 334; 336; 3263</td>
            </tr>
            <tr>
              <td>Heating system inhibitors</td>
              <td>IN-ECO 301; 390; 392; 3553</td>
            </tr>
            <tr>
              <td>Antifoamers</td>
              <td>IN-ECO 302-31</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Chemical treatment of cooling systems</div>
          <div className="content-text">IN-ECO reagents fully inhibit formation of mineral and organic deposits, minimise corrosion processes, excellent passivate different types of metals. This allows to work with the higher coefficients of concentration, carry out effective heat transfer for long periods of time.</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Open system corrosion and scale inhibitors</td>
              <td>IN-ECO 101; 103; 114; 131; 133; 134; 135; 150; 151</td>
            </tr>
            <tr>
              <td>Closed system corrosion and scale inhibitors</td>
              <td>IN-ECO 160; 161; 170; 174</td>
            </tr>
            <tr>
              <td>Non-oxidising biocides</td>
              <td>IN-ECO 264; 267; 268; 2641</td>
            </tr>
            <tr>
              <td>Oxidising biocides</td>
              <td>IN-ECO 210; 510; 511</td>
            </tr>
            <tr>
              <td>Bio-dispersants</td>
              <td>IN-ECO 250; 251</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Chemical treatment for reverse osmosis and ultrafiltration membrane technologies</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Antiscalants</td>
              <td>IN-ECO 550; 551; 552; 553; 554</td>
            </tr>
            <tr>
              <td>Concentrates for cleaning</td>
              <td>IN-ECO 535; 537; 538; 540; 541</td>
            </tr>
            <tr>
              <td>Preservatives</td>
              <td>IN-ECO 501; 502</td>
            </tr>
            <tr>
              <td>Disinfection and sanitation</td>
              <td>IN-ECO 510; 513; 515</td>
            </tr>
            <tr>
              <td>Additional chemicals for pre-treatment and correction</td>
              <td>IN-ECO 600; 210; 501</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Reagents for maintenance and cleaning of heat exchange equipment</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Cleaning of heat exchangers and boilers</td>
              <td>IN-ECO 401; 402; 403; 424</td>
            </tr>
            <tr>
              <td>Passivation, pre-treatment and degreasing</td>
              <td>IN-ECO 411; 412; 4121</td>
            </tr>
            <tr>
              <td>Coagulants and flocculants for pre-treatment</td>
              <td>IN-ECO 600; 6025; 6015; 882; 850</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Ion exchange resins and various filter media</div>
          <div className="content-text">We can offer full range of Purolite resins (cationic, anionic, mixed bed) and various media for mechanical filtrations and deironization equipment.</div>
        </div>
        <Sidebar
          analysis='Questionnaires'
          boiler='Boiler'
          cooling='Cooling'
          ro='RO'
          broshure='IN-ECO brochure'
          certificates='Certificates'
        />
      </div> 
    </Layout>

)

export default WaterTreatment